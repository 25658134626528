import heroVid from '../assets/growm_homepage_herovid.mp4';

export const LoadingScreen = ({ started, onStarted }) => {
    return (
        <div className={`loadingScreen ${started ? "loadingScreen--started" : ""}`}>
            <div className={'loadingScreen'}>
                <video autoPlay muted playsInline onClick={onStarted}> {/* Corrected attributes */}
                    <source src={heroVid} type="video/mp4" /> {/* Closed the <source> tag */}
                </video>
            </div>
        </div>
    );
};

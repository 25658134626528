import { useState, Suspense } from 'react'
import { Canvas } from '@react-three/fiber'
import { Experience } from "./components/Experience";
import { LoadingScreen } from "./components/LoadingScreen";

function App() {
  const [start, setStart] = useState(false);

  return (
    <>
        <Canvas dpr={[1, 2]} flat camera={{ fov: 75, position: [0, 0, 20] }} eventSource={document.getElementById('root')} eventPrefix="client">
          <Suspense fallback={null}>{start && <Experience />}</Suspense>
        </Canvas>
        <LoadingScreen started={start} onStarted={() => setStart(true)} />
    </>
  );
}

export default App;

import * as THREE from 'three';
import { useEffect, useState, useRef } from 'react';
import { extend, useThree } from '@react-three/fiber';
import { useCursor, Text, Preload, OrbitControls, AdaptiveDpr, PerformanceMonitor } from '@react-three/drei';
import { useRoute, useLocation } from 'wouter';
import { geometry } from 'maath';
import regular from "../fonts/Futura_Light_font.ttf";
import bold from "../fonts/Futura_Bold_font.ttf";
import img_ecommerce from '../assets/ecommerce.webp';
import img_architecture from '../assets/architecture.webp';
import img_interiordesign from '../assets/interiordesign.webp';
import env_ecommerce from '../assets/env_ecommerce.jpg';
import env_architecture from '../assets/env_architecture.jpg';
import env_interiordesign from '../assets/env_interiordesign.jpg';

extend(THREE);
extend(geometry);

export const Experience = () => {
    const [dpr, setDpr] = useState(1.5);
    const [, params] = useRoute('/item/:id'); // Capture route params to know if we're in a scene

    return (
        <>
            <AdaptiveDpr pixelated />
            <PerformanceMonitor onIncline={() => setDpr(2)} onDecline={() => setDpr(1)} />
            <color attach="background" args={['#000000']} />

            {/* Conditionally render "Double click to enter space" text only when outside a scene */}
            {params?.id === undefined && (
                <Text
                    outlineBlur={0.005}
                    font={regular}
                    fontSize={0.08}
                    textAlign={'center'}
                    lineHeight={1.2}
                    anchorX="center"
                    position={[0, 1.1, -0.25]}
                    material-toneMapped={false}
                >
                    Double click to enter a space
                </Text>
            )}

            {/* Render frames only when not inside a specific scene */}
            {params?.id === undefined && (
                <>
                    <Frame
                        id="01"
                        name={`online\nretail`}
                        tagline={`Unlock emotive shopping.`}
                        bg="black"
                        NRender={0}
                        position={[-1.2, 0, 0]}
                        rotation={[0, 0.5, 0]}
                        backgroundFile={img_ecommerce}
                        environmentFile={env_ecommerce}
                    />
                    <Frame
                        id="02"
                        name={`interior\ndesign`}
                        tagline={`Inspire & engage clients.`}
                        bg="black"
                        NRender={0}
                        position={[0, 0, -0.25]}
                        backgroundFile={img_interiordesign}
                        environmentFile={env_interiordesign}
                    />
                    <Frame
                        id="03"
                        name={`archi-\ntecture`}
                        tagline={`Bring projects to life.`}
                        bg="black"
                        NRender={0}
                        position={[1.2, 0, 0]}
                        rotation={[0, -0.5, 0]}
                        backgroundFile={img_architecture}
                        environmentFile={env_architecture}
                    />
                </>
            )}

            {/* Environment should only show when a scene is active */}
            {params?.id && (
                <EnvironmentScene params={params} />
            )}

            <Preload all />
            <Rig />
        </>
    );
};


function Frame({ id, name, tagline, bg, NRender, width = 1, height = 1.8, backgroundFile, environmentFile, ...props }) {
    const [texture, setTexture] = useState(null);
    const [, setLocation] = useLocation();
    const [, params] = useRoute('/item/:id');
    const [hovered, hover] = useState(false);
    const [isActive, setIsActive] = useState(false); // To track if the current frame is active
    const doubleTapInterval = 300;

    console.log(texture)

    // Load the texture asynchronously, handling WebP
    useEffect(() => {
        const textureLoader = new THREE.TextureLoader();
        textureLoader.load(
            backgroundFile,
            (loadedTexture) => {
                setTexture(loadedTexture); // Set texture once loaded successfully
            },
            undefined,
            (error) => {
                console.error('Error loading texture:', error);
            }
        );
    }, [backgroundFile]);

    // This effect updates isActive whenever the route matches the current frame
    useEffect(() => {
        setIsActive(params?.id === id);
    }, [params, id]);

    useCursor(hovered);

    const handleDoubleClick = (e) => {
        e.stopPropagation();
        // Exit the scene by setting location to the default route if already in the current scene
        if (isActive) {
            setLocation('/'); // or setLocation('/home') if you want a specific default route
        } else {
            // Navigate to the clicked item
            setLocation('/item/' + e.object.name);
        }
    };

    let lastTap = 0;

    const handleTouchDoubleClick = (e) => {
    e.stopPropagation(); // Prevent event from propagating further

    const currentTime = new Date().getTime();
    const tapInterval = currentTime - lastTap;

    // Check if the second tap is within doubleTapInterval of the first tap
    if (tapInterval < doubleTapInterval && tapInterval > 0) {
        handleDoubleClick(e); // Call the original double-click function
    }

    // Update the lastTap timestamp
    lastTap = currentTime;
    };

    return (
        <group {...props}>
            <ambientLight />
            <Text
                outlineBlur={0.01}
                font={bold}
                anchorY="top"
                fontSize={0.3}
                anchorX="left"
                lineHeight={0.8}
                position={[-0.375, 0.715, 0.05]}
                material-toneMapped={false}
            >
                {name}
            </Text>

            {hovered && (
                <Text
                    outlineBlur={0.005}
                    font={regular}
                    fontSize={0.08}
                    textAlign={'center'}
                    lineHeight={1.2}
                    anchorX="center"
                    position={[0, -1, 0.01]}
                    material-toneMapped={false}
                >
                    {tagline}
                </Text>
            )}

            {texture && (
                <mesh
                    name={id}
                    onDoubleClick={handleDoubleClick}
                    onPointerDown={(e) => handleTouchDoubleClick(e)}
                    onPointerOver={() => hover(true)}
                    onPointerOut={() => hover(false)}

                >
                    <roundedPlaneGeometry args={[width, height, 0.1]} />
                    <meshStandardMaterial map={texture} side={THREE.DoubleSide} />
                </mesh>
            )}
        </group>
    );
}

function EnvironmentScene({ params }) {
    const environmentFiles = {
        '01': env_ecommerce,
        '02': env_interiordesign,
        '03': env_architecture
    };

    const environmentRotations = {
        '01': [0, 1.9, 0],
        '02': [0, 3.6, 0],
        '03': [0, 1.3, 0]
    };

    const environmentFile = environmentFiles[params?.id];
    const environmentRotation = environmentRotations[params?.id];
    const [currentEnv, setCurrentEnv] = useState(null);
    const environmentTextureRef = useRef(null);
    const environmentMeshRef = useRef(null); // Reference to the environment mesh

    const [textureLoaded, setTextureLoaded] = useState(false);

    useEffect(() => {
        if (environmentFile && environmentFile !== currentEnv) {
            setCurrentEnv(environmentFile);
            if (environmentTextureRef.current) {
                environmentTextureRef.current.dispose(); // Dispose of the previous texture
            }

            const textureLoader = new THREE.TextureLoader();
            textureLoader.load(
                environmentFile,
                (loadedTexture) => {
                    loadedTexture.mapping = THREE.EquirectangularReflectionMapping;
                    environmentTextureRef.current = loadedTexture;

                    // Flip texture horizontally (X axis)
                    environmentTextureRef.current.repeat.x = -1;
                    environmentTextureRef.current.offset.x = 1; // Adjust the offset to match

                    environmentTextureRef.current.needsUpdate = true;
                    setTextureLoaded(true); // Texture is ready
                },
                undefined,
                (error) => {
                    console.error('Error loading texture:', error);
                }
            );
        }
    }, [environmentFile, currentEnv]);

    // Apply rotation once the texture is loaded
    useEffect(() => {
        if (environmentMeshRef.current && environmentRotation) {
            const [x, y, z] = environmentRotation;
            environmentMeshRef.current.rotation.set(x, y, z);
        }
    }, [environmentRotation]);

    useEffect(() => {
        return () => {
            if (environmentTextureRef.current) {
                environmentTextureRef.current.dispose();
            }
        };
    }, []);

    return textureLoaded ? (
        <mesh ref={environmentMeshRef}>
            <sphereGeometry args={[500, 60, 40]} />
            <meshBasicMaterial
                map={environmentTextureRef.current}
                side={THREE.BackSide}
            />
        </mesh>
    ) : (
        <></> // Show a loading state or nothing while texture is being loaded
    );
}



function Rig({ position = new THREE.Vector3(0, 0, 1.8), focus = new THREE.Vector3(0, 0, 0) }) {
    const { camera, scene } = useThree();
    const [, params] = useRoute('/item/:id'); // This should capture the route params

    useEffect(() => {
        // Set default camera position, rotation, and target
        const defaultPosition = new THREE.Vector3(0, 0, 2);
        const defaultFocus = new THREE.Vector3(0, 0, 0);

        camera.position.copy(defaultPosition);
        camera.rotation.set(0, 0, 0);

        const controls = scene.getObjectByName('orbitControls');
        if (controls) {
            controls.target.copy(defaultFocus); // Set focus point (target of the orbit controls)
        }
    }, [camera, scene, params?.id]);

    return (
        <OrbitControls
            enablePan={false}
            enableDamping={false}
            enableZoom={false}
            makeDefault
            minPolarAngle={0}
            maxPolarAngle={Math.PI / 2}
        />
    );
}
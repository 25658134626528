import { createRoot } from 'react-dom/client'
import { useRoute, useLocation } from 'wouter'
import './styles.css'
import App from "./App"

function Root() {
  const [, params] = useRoute('/item/:id')
  const [, setLocation] = useLocation()

  // Check if a portal is active
  const isPortalActive = Boolean(params?.id);

  // Define the URL based on the route (or portal entered)
  let linkUrl = "https://www.growm.io/"; // Default URL
  if (params) {
    // Update the URL dynamically based on the params.id
    // You can map params.id to specific URLs or paths.
    switch (params.id) {
      case '01':
        linkUrl = "https://www.growm.io/ecommerce";
        break;
      case '02':
        linkUrl = "https://www.growm.io/interior-design";
        break;
      case '03':
        linkUrl = "https://www.growm.io/architecture";
        break;
      // Add more cases here based on the available portal IDs
      default:
        linkUrl = "https://www.growm.io/";
    }
  }

  // Exit portal and navigate to the external URL
  const handleEnterClick = (e) => {
    // Prevent the default anchor tag behaviour (so the page doesn't immediately navigate away)
    e.preventDefault();

    // Exit the portal
    setLocation('/');

    // Navigate to the specific URL after exiting the portal
    setTimeout(() => {
      window.location.href = linkUrl;
    }, 100); // A short delay to ensure the portal exit happens first
  };

  return (
    <>
      <div style={{ width: '100%', height: '100vh' }}>
        <App />
      </div>
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100vh' }}>
        <div className="react-container-main">
          <div className={`react-container-box ${isPortalActive ? 'visible' : 'hidden'}`}>
            <div className='react-container-text'>
              <a
                className="react-heading"
                href={linkUrl}
                onClick={handleEnterClick}
              >
                {params ? 'ENTER' : ''}
              </a>
            </div>
            <div className='react-container-text'>
              <a className="react-text" href="#" onClick={() => setLocation('/')}>
                {params ? 'click to go back' : ''}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

createRoot(document.getElementById('root')).render(<Root />)
